/* Slider */ export const slickThemeStyle = `
  @charset "UTF-8";

  // Default Variables

  $slick-font-path: "./fonts/" !default;
  $slick-font-family: "slick" !default;
  $slick-loader-path: "./" !default;
  $slick-arrow-color: white !default;
  $slick-dot-color: black !default;
  $slick-dot-color-active: $slick-dot-color !default;
  $slick-dot-size: 0.338rem !default;
  $slick-opacity-default: 0.75 !default;
  $slick-opacity-on-hover: 1 !default;
  $slick-opacity-not-active: 0.25 !default;

  @function slick-image-url($url) {
    @if function-exists(image-url) {
      @return image-url($url);
    } @else {
      @return url($slick-loader-path+$url);
    }
  }

  @function slick-font-url($url) {
    @if function-exists(font-url) {
      @return font-url($url);
    } @else {
      @return url($slick-font-path+$url);
    }
  }

  /* Slider */

  .slick-list {
    .slick-loading & {
      background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
    }
  }

  /* Arrows */

  .slick-prev,
  .slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0rem;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    &:hover,
    &:focus {
      outline: none;
      background: transparent;
      color: transparent;
      &:before {
        opacity: $slick-opacity-on-hover;
      }
    }
    &.slick-disabled:before {
      opacity: $slick-opacity-not-active;
    }
    &:before {
      font-family: $slick-font-family;
      font-size: 1.125rem;
      line-height: 1;
      color: $slick-arrow-color;
      opacity: $slick-opacity-default;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  .slick-prev {
    left: -25px;
    [dir="rtl"] & {
      left: auto;
      right: -25px;
    }
  }

  .slick-next {
    right: -25px;
    [dir="rtl"] & {
      left: -25px;
      right: auto;
    }
  }

  /* Dots */

  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }

  .slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
      position: relative;
      display: inline-block;
      height: 20px;
      width: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;
      button {
        border: 0;
        background: transparent;
        display: block;
        height: 20px;
        width: 20px;
        outline: none;
        line-height: 0px;
        font-size: 0rem;
        color: transparent;
        padding: 5px;
        cursor: pointer;
        &:hover,
        &:focus {
          outline: none;
          &:before {
            opacity: $slick-opacity-on-hover;
          }
        }
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          font-family: $slick-font-family;
          font-size: $slick-dot-size;
          line-height: 20px;
          text-align: center;
          color: $slick-dot-color;
          opacity: $slick-opacity-not-active;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
      &.slick-active button:before {
        color: $slick-dot-color-active;
        opacity: $slick-opacity-default;
      }
    }
  }
`;
