export const LAYOUT_OPTION_VALUE = "layout";
import { LAYOUT_CLICKED, LAYOUT_SELECTED } from "/constants/bigbrain-event-types";
export const LAYOUT_CONFIG = {
    firstStepHtmlTitle: "<strong>What would you like to <br>manage with monday.com?</strong>",
    secondStepHtmlTitle: "That's great! <br><strong>Now let's get to work</strong>",
    otherDetailsHtmlTitle: "With more than 200 templates<br><strong>we got you covered</strong>",
    subtitleHtml: "Pick one, you can easily<br>change it later.",
    itemClickedEvent: LAYOUT_CLICKED,
    itemSelectedEvent: LAYOUT_SELECTED,
    options: [
        {
            id: "tasks",
            text: "Task List",
            image: "/static/img/layout-selection/task_list.png",
            backgroundColor: "brand-iris"
        },
        {
            id: "timeline",
            text: "Timeline",
            image: "/static/img/layout-selection/timeline.png",
            backgroundColor: "brand-yellow"
        },
        {
            id: "processManagement",
            text: "Process Management",
            image: "/static/img/layout-selection/process_management.png",
            backgroundColor: "brand-red"
        },
        {
            id: "kanaban",
            text: "Kanban",
            image: "/static/img/layout-selection/kanban.png",
            backgroundColor: "brand-purple"
        },
        {
            id: "calendar",
            text: "Calendar",
            image: "/static/img/layout-selection/calendar.png",
            backgroundColor: "brand-light-blue"
        },
        {
            id: "list",
            text: "List",
            image: "/static/img/layout-selection/list.png",
            backgroundColor: "brand-green"
        },
        {
            id: "other",
            text: "Other",
            image: "/static/img/layout-selection/other.png",
            detailsImage: "/static/img/layout-selection/other_details.png",
            detailsText: "Manage anything with monday.com",
            isOther: true,
            backgroundColor: "dark-blue"
        }
    ]
};
