import styled from "@emotion/styled";
import { MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";
export const StyledIntegrationsComponent = styled.div`
  .image-gallery {
    margin: 16px;
    width: 20%;

    .social-proof-gallery-picture {
      img {
        width: 100%;
      }
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    .images-gallery-row {
      .image-gallery {
        margin: 8px;
        width: 44%;
      }
    }
  }
`;
