import styled from "@emotion/styled";
import { MAX_DESKTOP_CONTENT_WIDTH } from "constants/vp-sizes";
export const StyledMainYoutubeVideoComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .video-component-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: ${({ width })=>width};
    height: ${({ height })=>height};

    .youtube-video-with-poster-component {
      box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
    }

    .video-decoration-wrapper {
      position: absolute;
      top: -40px;
      left: -40px;
      align-self: flex-start;
    }

    .poster-image-wrapper img,
    iframe {
      width: ${({ width })=>width};
      height: ${({ height })=>height};
    }

    .lottie-animation-wrapper {
      position: absolute;
      right: -100px;
      top: 70%;

      .lottie-animation-component {
        height: 115px;
        width: 88px;
      }

      .animation-text {
        transform: translateX(36%);
        max-width: 100%;
      }
    }
  }

  .main-video-title {
    margin-top: 24px;
    font-size: 1.375rem;
  }

  @media (max-width: ${MAX_DESKTOP_CONTENT_WIDTH + 100}px) {
    .video-component-wrapper {
      width: 90vw;
      height: 50vw;

      .video-decoration-wrapper {
        transform: rotateZ(30deg);
        top: -36px;
        left: -14px;

        svg {
          width: 4vw;
        }
      }

      .poster-image-wrapper img,
      iframe {
        width: 90vw;
        height: 50vw;
      }

      .lottie-animation-wrapper {
        display: none;
      }
    }

    .main-video-title {
      margin-top: 16px;
    }
  }
`;
