import colors from "/styles/colors";
import { TABLET_QUERY_MIN_WIDTH } from "constants/vp-sizes";
export const componentStyles = new String(`.person-component.jsx-4021719501c349b9{display:-webkit-box;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-webkit-align-items:center;-moz-box-align:center;-ms-flex-align:center;align-items:center;.person-image.jsx-4021719501c349b9{margin-right:1.5vw;img {
        border-radius: 50%;
        height: 5vw;
        min-height: 32px;
      }
    }.text-container.jsx-4021719501c349b9{display:-webkit-box;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;-moz-box-orient:vertical;-moz-box-direction:normal;-ms-flex-direction:column;flex-direction:column;text-align:left;line-height:3vw;.text.jsx-4021719501c349b9{font-size:2vw;a,
        span {
          font-weight: 400;
        }
      @media(max-width:${TABLET_QUERY_MIN_WIDTH}){font-size:1.125rem;line-height:24px}}.name-and-vendor-container.jsx-4021719501c349b9{.person-name.jsx-4021719501c349b9{text-decoration:none;color:${colors["black"]};font-family:"Reenie Beanie",cursive;font-size:2.5vw;@media(max-width:${TABLET_QUERY_MIN_WIDTH}){font-size:1.375rem}}.vendor-name.jsx-4021719501c349b9{color:${colors["black"]};text-decoration:none}}}}`);
componentStyles.__hash = "4021719501c349b9";
