import styled from "@emotion/styled";
export const StyledBoardsAndQoutesCarouselComponent = styled.div`
  .boards-carousel-wrapper {
    position: relative;
  }

  .people-carousel-wrapper {
    margin-top: 60px;
  }
`;
