import styled from "@emotion/styled";
import { MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";
import colors from "styles/colors";
const titleFontSize = "56px";
export const StyledFactDetailsComponent = styled.div`
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  color: ${colors["white"]};

  .title {
    font-weight: 700;
    font-size: ${titleFontSize};
    margin-right: 36px;
    line-height: ${titleFontSize};
  }

  .content {
    flex-grow: 1;
    font-size: 1.375rem;
    line-height: 34px;
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    flex-direction: column;
    text-align: center;
    padding: 0px 32px;

    .title {
      margin-top: 36px;
      margin-right: 0px;
    }

    .content {
      line-height: 30px;
      margin-top: 18px;
    }
  }
`;
