import styled from "@emotion/styled";
import { MOBILE_QUERY_MAX_WIDTH, FOLD_TEST_MAX_WIDTH } from "constants/vp-sizes";
export const StyledSwitchingTitleComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 16px;

  font-size: 2.75rem;
  font-weight: 500;

  @media (min-width: ${FOLD_TEST_MAX_WIDTH}) {
    &.above-the-fold {
      flex-direction: column;

      text-align: left;
      font-size: 3.938rem;

      .switching-words-wrapper {
        min-height: 80px;
      }
    }
  }

  .colorful-title-wrapper {
    margin-right: 10px;
  }

  .switching-words-wrapper {
    align-self: stretch;
    display: flex;
  }

  .colorful-title,
  .colorful-title .colored,
  .switching-word-item-wrapper {
    font-weight: 700;
    font-size: 2.75rem;
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    font-size: 2.25rem;
  }
`;
