import styled from "@emotion/styled";
import colors from "/styles/colors";
export const StyledBoardViewsComponent = styled.div`
  .lottie-gallery-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 24px;
  }

  .paragraph-component {
    .body {
      max-width: 650px;
    }
  }
`;
export const StylesLottieItemWrapper = styled.div`
  max-width: 300px;
  margin: 16px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  a {
    display: block;
    text-decoration: none;
    color: ${colors["black"]};
  }

  .board-view-item-text {
    padding: 16px;
    text-align: center;
  }

  .board-view-item-title {
    margin-bottom: 8px;
  }
`;
