import styled from "@emotion/styled";
import { FOLD_TEST_MAX_WIDTH, TABLET_QUERY_MAX_WIDTH } from "constants/vp-sizes";
export const StyledSwitchingTitleWithSignupComponent = styled.div`
  text-align: center;
  padding: 0 16px;

  .subtitle-wrapper {
    &.mobile-only {
      display: none;
    }

    position: relative;
    margin-bottom: 46px;
    margin-top: 24px;

    h2 {
      line-height: 1.3;
      font-size: 1.125rem;
    }
  }

  .signup-form-with-decorations-wrapper {
    display: flex;
    justify-content: center;
  }

  .arrow-wrapper {
    display: none;
  }

  @media (min-width: ${FOLD_TEST_MAX_WIDTH}) {
    &.above-the-fold {
      .subtitle-wrapper {
        margin-bottom: 24px;

        text-align: left;

        h2 {
          line-height: 1.5;
          font-size: 1.375rem;
        }
      }

      .signup-form-with-decorations-wrapper {
        justify-content: flex-start;
      }

      .arrow-wrapper {
        display: block;
        padding-top: 20px;
      }
    }
  }

  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
    .subtitle-wrapper {
      &.desktop-only {
        display: none;
      }
    }

    .switching-title-wrapper {
      &.subtitle-desktop-only {
        margin-bottom: 46px; // instead of the subtitle margin-bottom
      }

      font-size: 1.75rem;
    }

    .subtitle-wrapper {
      h2 {
        font-size: 1.125rem;
      }
    }
  }
`;
