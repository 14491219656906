import colors from "styles/colors";
export const ELEMENT_DIMENSIONS = {
    CLUSTER_SELECTION_ITEM_HEIGHT: 66,
    CLUSTER_SELECTION_ITEM_WIDTH: 540,
    CLUSTER_SELECTION_ITEM_MARGIN: 16
};
export const OPEN_SIGNUP_ITEM_ANIMATION_DURATION = 300;
export const CLUSTERS_SELECTION_CONFIG = [
    {
        id: "internal_projects",
        imgSrc: "/static/img/clusters-selection/projects_and_tasks.png",
        imgDimensions: {
            width: 20
        },
        title: "Projects & Tasks",
        color: colors["brand-dark-blue"],
        activeQuote: {
            personImage: "/static/img/clusters-selection/Ben_Allen_EQC_IT.png",
            personTitle: "Ben Allen, EQC IT",
            quoteText: '"It is a single easy to read one stop to see what projects are in the works and where we are at as well as plans for the future."'
        }
    },
    {
        id: "marketing",
        imgSrc: "/static/img/clusters-selection/marketing_and_creative.png",
        title: "Marketing & Creative",
        color: colors["brand-yellow"],
        activeQuote: {
            personImage: "/static/img/clusters-selection/Jelena_Tomic_Bell.png",
            personTitle: "Jelena Tomic, Bell",
            quoteText: '"It increases efficiency and overall communication, we are able to finish this within a week as opposed to previously. Without monday.com, it took us almost a month."'
        }
    },
    {
        id: "crm",
        imgSrc: "/static/img/clusters-selection/sales_and_crm.png",
        title: "Sales & CRM",
        color: colors["brand-blue"],
        activeQuote: {
            personImage: "/static/img/clusters-selection/Aaste_Palczewski_Lostcabincapital.png",
            personTitle: "Aaste Palczewski, Lostcabincapital",
            quoteText: '"My team and I know exactly who we need to contact and where we are at with potential clients."'
        }
    },
    {
        id: "orders",
        imgSrc: "/static/img/clusters-selection/orders_and_production.png",
        title: "Orders & Production",
        color: colors["brand-green"],
        activeQuote: {
            personImage: "/static/img/clusters-selection/Gabriela_Luna",
            personTitle: "Gabriela Luna, Alvear",
            quoteText: '"monday.com has helped us keep all in order and allows everyone on our team to plan accordingly knowing exactly when it all will happen."'
        }
    },
    {
        id: "tech",
        imgSrc: "/static/img/clusters-selection/product_and_rnd.png",
        title: "Product & R&D",
        color: colors["brand-red"],
        activeQuote: {
            personImage: "/static/img/clusters-selection/Shachar_Tal_Loginno.png",
            personTitle: "Shachar Tal, Loginno",
            quoteText: '"Within a minute we understand exactly what is the status. It negates the need to have a weekly meeting and actually saves us time and improves our dev speed."'
        }
    },
    {
        id: "other",
        imgSrc: "/static/img/clusters-selection/other.png",
        title: "Other",
        color: colors["brand-purple"],
        activeQuote: {
            personImage: "/static/img/clusters-selection/Ben_Allen_EQC_IT.png",
            personTitle: "Ben Allen, EQC IT",
            quoteText: '"It is a single easy to read one stop to see what projects are in the works and where we are at as well as plans for the future."'
        }
    }
];
