import styled from "@emotion/styled";
import { FOLD_TEST_MAX_WIDTH, MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";
import { TOP_LAYER } from "constants/z-index";
export const StyledSwitchingTitleWithQuotesCarouselComponent = styled.div`
  .switching-title-with-signup-wrapper {
    margin-bottom: 64px;
  }

  @media (min-width: ${FOLD_TEST_MAX_WIDTH}) {
    &.above-the-fold {
      display: flex;
      justify-content: center;
      min-width: 1410px;
      margin-left: -180px; //calc: (1410 - 1050) /2

      .switching-title-with-signup-wrapper {
        margin-bottom: 0;
        padding-top: 45px;

        padding-left: 5vw;
        min-width: 610px;
        z-index: ${TOP_LAYER};
        background: white;
      }

      .boards-and-quotes-carousel-wrapper {
        margin-left: 32px;
        min-width: 0;
        padding-top: 24px;
      }
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    .switching-title-with-signup-wrapper {
      margin-bottom: 32px;
    }
  }
`;
