import styled from "@emotion/styled";
import { MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";
export const StyledFactCardComponent = styled.div`
  display: flex;
  flex-direction: column;

  .fact-details-wrapper {
    align-self: flex-start;
  }

  .fact-quote-wrapper {
    transform: translateY(-40px);
    align-self: flex-end;
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    align-items: center;

    .fact-details-wrapper {
      align-self: auto;
      width: 100%;
      height: 300px;
    }

    .fact-quote-wrapper {
      width: 90%;
      transform: translateY(-50px);
      align-self: auto;
    }
  }
`;
