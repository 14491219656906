import styled from "@emotion/styled";
export const StyledCarouselRoundArrowComponent = styled.div`
  &.slick-arrow {
    border-radius: 50%;
    background-color: white;
    border: 1px solid black;
    width: 37px;
    height: 37px;
    top: calc(50% - 38px);

    &.slick-prev {
      left: -64px;

      &::before {
        position: absolute;
        right: 11px;
        top: 12px;
      }
    }

    &.slick-next {
      right: -60px;

      &::before {
        position: absolute;
        right: 14px;
        top: 12px;
      }
    }
  }
`;
