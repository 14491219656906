import styled from "@emotion/styled";
import colors from "/styles/colors";
import { LAYER_ONE, ZERO } from "constants/z-index";
import { MOBILE_QUERY_MAX_WIDTH, XS } from "/constants/vp-sizes";
export const StyledHeaderWithImageAndTitleComponent = styled.div`
  position: relative;

  .header-with-image-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: ${LAYER_ONE};

    .signup-form {
      margin-top: 32px;
    }

    .title {
      margin-top: 4vh;
      margin-bottom: 2vh;
      text-align: center;
      max-width: 80vw;
      font-size: 3.5vw;
      font-weight: 300;

      :global(b) {
        font-weight: 500;
      }
    }

    .subtitle {
      font-size: 1.8vw;
      margin-top: 0;
      font-weight: 300;
      max-width: 80vw;
      text-align: center;
      line-height: 2.4vw;
      margin-bottom: 10px;
    }

    &.white-text {
      .title,
      .subtitle {
        color: ${colors["white"]};
      }
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    .header-with-image-content {
      min-height: auto;
      padding: 0px 20px;
      text-align: center;

      .title {
        font-size: 1.575rem;
        margin-bottom: 1.5vh;
        line-height: 1.3;
        font-weight: 300;
      }

      .subtitle {
        font-size: 0.875rem;
        line-height: 1.5;
      }
    }
  }

  .hot-dog {
    content: "";
    position: absolute;
    width: 120vw;
    height: 120vw;
    background: ${({ hotDogColor })=>colors[hotDogColor]};
    border-radius: 999px;
    top: -88vw;
    left: -10vw;
    z-index: ${ZERO};

    @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
      width: 210vw;
      height: 220vw;
      max-height: 160vh;
      left: -50vw;
    }

    @media (max-width: ${XS}px) {
      top: -110vw;
      width: 220vw;
      height: 220vw;
      max-height: 140vh;
      left: -55vw;
    }
  }

  .header-with-image-content {
    margin-bottom: 50px;

    .main-image {
      position: relative;
      width: ${({ imageWidth })=>imageWidth};
      margin-top: 48px;

      &.with-shadow {
        img {
          box-shadow: 0 2px 40px 1px rgba(60, 60, 60, 0.3);
          border: 12px solid white;
          border-radius: 6px;
        }
      }

      img {
        width: 100%;
      }
    }

    @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
      .main-image {
        width: 90%;
        margin-bottom: 20px;
      }
    }
  }
`;
