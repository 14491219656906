import styled from "@emotion/styled";
import { MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";
export const StyledCardComponent = styled.div`
  border-radius: 5px;
  padding: 24px 40px;
  box-shadow: 0px 2px 16px 0px rgba(195, 195, 195, 0.5);

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    padding: 0px;
    height: 100% !important;
    width: 100% !important;
  }
`;
