import { MOBILE_CLUSTER_CLICKED, MOBILE_CLUSTER_SELECTED } from "/constants/bigbrain-event-types";
export const CLUSTER_MOBILE_OPTION_VALUE = "cluster-mobile";
export const CLUSTER_CONFIG_MOBILE = {
    firstStepHtmlTitle: "<strong>What would you like to manage with monday.com?</strong>",
    secondStepHtmlTitle: "That's great! <br><strong>Now, let's get to work</strong>",
    otherDetailsHtmlTitle: "That's great! <br><strong>Now, let's get to work</strong>",
    itemClickedEvent: MOBILE_CLUSTER_CLICKED,
    itemSelectedEvent: MOBILE_CLUSTER_SELECTED,
    withoutContinueButton: true,
    ctaText: "Try it out",
    options: [
        {
            id: "internal_projects",
            text: "Projects",
            image: "/static/img/cluster-selection/projects.png"
        },
        {
            id: "client_projects",
            text: "Projects for Clients",
            image: "/static/img/cluster-selection/client_projects.png"
        },
        {
            id: "todos",
            text: "Tasks & To Do's",
            image: "/static/img/cluster-selection/todos.png"
        },
        {
            id: "crm",
            text: "Sales & CRM",
            image: "/static/img/cluster-selection/crm.png"
        },
        {
            id: "orders",
            text: "Orders & Production",
            image: "/static/img/cluster-selection/orders.png"
        },
        {
            id: "product_roadmap",
            text: "Product Roadmap",
            image: "/static/img/cluster-selection/product_roadmap.png"
        },
        {
            id: "tech",
            text: "Software Development",
            image: "/static/img/cluster-selection/agile.png"
        },
        {
            id: "other",
            text: "I'll decide later",
            image: "/static/img/cluster-selection/other.png",
            detailsImage: "/static/img/cluster-selection/other_details.png",
            detailsText: "Manage anything with monday.com",
            isOther: true
        }
    ]
};
