import styled from "@emotion/styled";
export const StyledSwitchingWordsComponent = styled.div`
  @keyframes animateEntrance {
    0% {
      opacity: 0;
      transform: translate(0px, -40px);
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
      transform: translate(0px, 0px);
    }
  }

  @keyframes animateExit {
    0% {
      opacity: 1;
      transform: translate(0px, 0px);
    }
    37% {
      transform: translate(0px, -10px);
    }
    75% {
      transform: translate(0px, 51px);
    }
    100% {
      opacity: 1;
      transform: translate(0px, 60px);
    }
  }

  position: relative;
  align-self: stretch;

  text-align: left;

  &:after {
    content: "";
    position: absolute;
    background: #fff;

    left: 0;
    bottom: -74px;

    width: 400px;
    height: 68px;
  }

  .switching-word-item-wrapper {
    position: absolute;
    top: 0;

    &.active:not(.animate-entrance):not(.animate-exit) {
      opacity: 1;
    }

    &.animate-entrance {
      animation-name: animateEntrance;
      animation-duration: 0.8s;
      animation-iteration-count: 1;
      animation-timing-function: cubic-bezier(0.58, 0.1, 0.6, 1.26);
    }

    &.animate-exit {
      animation-name: animateExit;
      animation-duration: 0.8s;
      animation-iteration-count: 1;
      animation-timing-function: cubic-bezier(0, 0, 0, 0.91);
    }

    &:not(.animate-entrance) {
      opacity: 0;
    }
  }
`;
