const MANAGE_OPTION_TASKS = "Tasks";
const MANAGE_OPTION_RESOURCES = "Resources";
const MANAGE_OPTION_TIME_TRACKING = "Time tracking";
const MANAGE_OPTION_PROJECT_PLAN = "Project plan";
const MANAGE_OPTION_APPROVAL_PROCESS = "Approval process";
const MANAGE_OPTION_CLIENT_AND_BILLING = "Clients & Billing";
const MANAGE_OPTION_ROADMAP = "Roadmap";
const MANAGE_OPTION_TASK_ASSIGNMENT = "Task assignment";
const MANAGE_OPTION_TIMELINE = "Timeline";
const MANAGE_OPTION_PROGRESS_TRACKING = "Progress tracking";
const MANAGE_OPTION_SALES_PIPELINE = "Sales Pipeline";
const MANAGE_OPTION_CONTACTS = "Contacts";
const MANAGE_OPTION_PARTNERS = "Partners";
const MANAGE_OPTION_HR = "HR & Recruiting";
const MANAGE_OPTION_OPERATIONS = "Operations";
const MANAGE_OPTION_CAMPAIGNS = "Campaigns";
const MANAGE_OPTION_CREATIVE_ASSETS = "Creative assets";
const MANAGE_OPTION_EVENTS = "Events";
const MANAGE_OPTION_CAMPAIGN_SCHEDULE = "Campaign schedule";
const MANAGE_OPTION_SOCIAL_MEDIA_CALENDAR = "Social media calendar";
const MANAGE_OPTION_CREATIVE_REQUESTS = "Creative requests & approvals";
const MANAGE_OPTION_STORIES = "Stories & Epics";
const MANAGE_OPTION_SPRINTS = "Sprints";
const MANAGE_OPTION_FILES = "Files & assets";
const MANAGE_OPTION_BUGS = "Bugs queue";
const MANAGE_OPTION_CONTACTS_AND_LEADS = "Contacts & Leads";
const MANAGE_OPTION_PROJECT_GANTT = "Project gantt";
const MANAGE_OPTION_BACKLOG = "Backlog";
const MANAGE_OPTION_TICKETS_AND_REQUESTS = "Tickets & Requests";
const MANAGE_OPTION_EVENT_PLANNING = "Event planning";
const MANAGE_OPTION_INCOMMING_REQUESTS = "Incoming requests";
const MANAGE_OPTION_SUPPLY_CHAIN = "Supply chain";
const MANAGE_OPTION_ORDERS = "Orders";
const MANAGE_OPTION_PRODUCTION_FLOW = "Production Flow";
const MANAGE_OPTION_PROPERTIES = "Properties";
const MANAGE_OPTION_PAYMENTS_AND_BILLING = "Billing & Payments";
const MANAGE_OPTION_PROCESS_TRACKING = "Process tracking";
const MANAGE_OPTION_ASSIGNMENT = "Assignments";
const MANAGE_OPTION_RESEARCH = "Research";
const MANAGE_OPTION_ATTENDANCE = "Attendance";
const MANAGE_OPTION_DOCUMENTS = "Documents";
export const DEFAULT_CLUSTER = "client_projects";
export const INDUSTRIES_CONFIG = {
    firstStepHtmlTitle: "What does <strong>your team</strong> do?",
    secondStepHtmlTitle: "What would you like to <strong>manage</strong> with monday.com?",
    selectionDisclaimerHtml: "( Select as many as you want )",
    signupHtmlSubTitle: "We have the perfect solution waiting for you inside.<br>Sign up and get started.",
    options: [
        {
            value: "Accounting",
            label: "Accounting/Finance",
            clusterId: "client_projects",
            manageOptions: [
                MANAGE_OPTION_TIMELINE,
                MANAGE_OPTION_TASKS,
                MANAGE_OPTION_RESOURCES,
                MANAGE_OPTION_TIME_TRACKING,
                MANAGE_OPTION_APPROVAL_PROCESS,
                MANAGE_OPTION_PROJECT_PLAN,
                MANAGE_OPTION_CLIENT_AND_BILLING,
                MANAGE_OPTION_ROADMAP
            ]
        },
        {
            value: "Administrative",
            label: "Administrative/Operations",
            clusterId: "internal_projects",
            manageOptions: [
                MANAGE_OPTION_PROJECT_PLAN,
                MANAGE_OPTION_TASK_ASSIGNMENT,
                MANAGE_OPTION_TIMELINE,
                MANAGE_OPTION_RESOURCES,
                MANAGE_OPTION_TIME_TRACKING,
                MANAGE_OPTION_APPROVAL_PROCESS,
                MANAGE_OPTION_CLIENT_AND_BILLING,
                MANAGE_OPTION_PROGRESS_TRACKING
            ]
        },
        {
            value: "Architecture",
            label: "Architecture/Engineering",
            clusterId: "client_projects",
            manageOptions: [
                MANAGE_OPTION_PROJECT_PLAN,
                MANAGE_OPTION_PROCESS_TRACKING,
                MANAGE_OPTION_TASKS,
                MANAGE_OPTION_FILES,
                MANAGE_OPTION_CLIENT_AND_BILLING,
                MANAGE_OPTION_TIME_TRACKING,
                MANAGE_OPTION_APPROVAL_PROCESS,
                MANAGE_OPTION_TIMELINE,
                MANAGE_OPTION_OPERATIONS
            ]
        },
        {
            value: "Business Development",
            label: "Business Development",
            clusterId: "crm",
            manageOptions: [
                MANAGE_OPTION_TIMELINE,
                MANAGE_OPTION_SALES_PIPELINE,
                MANAGE_OPTION_CONTACTS,
                MANAGE_OPTION_TASK_ASSIGNMENT,
                MANAGE_OPTION_TIME_TRACKING,
                MANAGE_OPTION_PROJECT_PLAN,
                MANAGE_OPTION_PARTNERS,
                MANAGE_OPTION_PROGRESS_TRACKING
            ]
        },
        {
            value: "Business Owner",
            label: "Business Owner",
            clusterId: "internal_projects",
            manageOptions: [
                MANAGE_OPTION_PROJECT_PLAN,
                MANAGE_OPTION_TASK_ASSIGNMENT,
                MANAGE_OPTION_ROADMAP,
                MANAGE_OPTION_TIMELINE,
                MANAGE_OPTION_RESOURCES,
                MANAGE_OPTION_APPROVAL_PROCESS,
                MANAGE_OPTION_CLIENT_AND_BILLING,
                MANAGE_OPTION_HR,
                MANAGE_OPTION_OPERATIONS
            ]
        },
        {
            value: "Consulting",
            label: "Consulting",
            clusterId: "crm",
            manageOptions: [
                MANAGE_OPTION_PROJECT_PLAN,
                MANAGE_OPTION_TIME_TRACKING,
                MANAGE_OPTION_TASKS,
                MANAGE_OPTION_CLIENT_AND_BILLING,
                MANAGE_OPTION_PROGRESS_TRACKING,
                MANAGE_OPTION_APPROVAL_PROCESS,
                MANAGE_OPTION_RESOURCES,
                MANAGE_OPTION_TIMELINE
            ]
        },
        {
            value: "Creative",
            label: "Creative Services/Design",
            clusterId: "marketing",
            manageOptions: [
                MANAGE_OPTION_CREATIVE_REQUESTS,
                MANAGE_OPTION_CAMPAIGNS,
                MANAGE_OPTION_PROJECT_PLAN,
                MANAGE_OPTION_CREATIVE_ASSETS,
                MANAGE_OPTION_TIME_TRACKING,
                MANAGE_OPTION_CLIENT_AND_BILLING,
                MANAGE_OPTION_APPROVAL_PROCESS,
                MANAGE_OPTION_TASK_ASSIGNMENT
            ]
        },
        {
            value: "Education",
            label: "Education",
            clusterId: "internal_projects",
            manageOptions: [
                MANAGE_OPTION_PROJECT_PLAN,
                MANAGE_OPTION_TASKS,
                MANAGE_OPTION_ASSIGNMENT,
                MANAGE_OPTION_OPERATIONS,
                MANAGE_OPTION_RESOURCES,
                MANAGE_OPTION_RESEARCH,
                MANAGE_OPTION_EVENTS,
                MANAGE_OPTION_ATTENDANCE
            ]
        },
        {
            value: "Events",
            label: "Events",
            clusterId: "client_projects",
            manageOptions: [
                MANAGE_OPTION_TIMELINE,
                MANAGE_OPTION_TASK_ASSIGNMENT,
                MANAGE_OPTION_SOCIAL_MEDIA_CALENDAR,
                MANAGE_OPTION_CREATIVE_REQUESTS,
                MANAGE_OPTION_EVENT_PLANNING,
                MANAGE_OPTION_CLIENT_AND_BILLING,
                MANAGE_OPTION_TIME_TRACKING,
                MANAGE_OPTION_PROCESS_TRACKING
            ]
        },
        {
            value: "IT",
            label: "IT",
            clusterId: "client_projects",
            manageOptions: [
                MANAGE_OPTION_PROJECT_PLAN,
                MANAGE_OPTION_TASK_ASSIGNMENT,
                MANAGE_OPTION_TICKETS_AND_REQUESTS,
                MANAGE_OPTION_TIMELINE,
                MANAGE_OPTION_SPRINTS,
                MANAGE_OPTION_TIME_TRACKING,
                MANAGE_OPTION_CLIENT_AND_BILLING,
                MANAGE_OPTION_RESOURCES,
                MANAGE_OPTION_APPROVAL_PROCESS
            ]
        },
        {
            value: "Legal",
            label: "Legal",
            clusterId: "client_projects",
            manageOptions: [
                MANAGE_OPTION_PROJECT_PLAN,
                MANAGE_OPTION_TIMELINE,
                MANAGE_OPTION_TIME_TRACKING,
                MANAGE_OPTION_CLIENT_AND_BILLING,
                MANAGE_OPTION_TASKS,
                MANAGE_OPTION_DOCUMENTS,
                MANAGE_OPTION_INCOMMING_REQUESTS,
                MANAGE_OPTION_APPROVAL_PROCESS
            ]
        },
        {
            value: "Manufacturing",
            label: "Manufacturing/Production",
            clusterId: "orders",
            manageOptions: [
                MANAGE_OPTION_SUPPLY_CHAIN,
                MANAGE_OPTION_ORDERS,
                MANAGE_OPTION_PRODUCTION_FLOW,
                MANAGE_OPTION_RESOURCES,
                MANAGE_OPTION_TIMELINE,
                MANAGE_OPTION_TIME_TRACKING,
                MANAGE_OPTION_APPROVAL_PROCESS,
                MANAGE_OPTION_PROGRESS_TRACKING,
                MANAGE_OPTION_PROJECT_PLAN
            ]
        },
        {
            value: "Marketing",
            label: "Marketing/Media/Advertising",
            clusterId: "marketing",
            manageOptions: [
                MANAGE_OPTION_TIMELINE,
                MANAGE_OPTION_TASK_ASSIGNMENT,
                MANAGE_OPTION_CAMPAIGN_SCHEDULE,
                MANAGE_OPTION_SOCIAL_MEDIA_CALENDAR,
                MANAGE_OPTION_CREATIVE_REQUESTS,
                MANAGE_OPTION_EVENT_PLANNING,
                MANAGE_OPTION_CLIENT_AND_BILLING,
                MANAGE_OPTION_TIME_TRACKING,
                MANAGE_OPTION_PROGRESS_TRACKING
            ]
        },
        {
            value: "Product Management",
            label: "Product Management",
            clusterId: "tech",
            manageOptions: [
                MANAGE_OPTION_ROADMAP,
                MANAGE_OPTION_STORIES,
                MANAGE_OPTION_SPRINTS,
                MANAGE_OPTION_TASKS,
                MANAGE_OPTION_TIMELINE,
                MANAGE_OPTION_RESOURCES,
                MANAGE_OPTION_PROJECT_PLAN,
                MANAGE_OPTION_FILES
            ]
        },
        {
            value: "Project Management",
            label: "Project Management",
            clusterId: "internal_projects",
            manageOptions: [
                MANAGE_OPTION_PROJECT_PLAN,
                MANAGE_OPTION_TASK_ASSIGNMENT,
                MANAGE_OPTION_TIMELINE,
                MANAGE_OPTION_RESOURCES,
                MANAGE_OPTION_TIME_TRACKING,
                MANAGE_OPTION_APPROVAL_PROCESS,
                MANAGE_OPTION_CLIENT_AND_BILLING,
                MANAGE_OPTION_PROGRESS_TRACKING,
                MANAGE_OPTION_ROADMAP
            ]
        },
        {
            value: "Real Estate",
            label: "Real Estate",
            clusterId: "crm",
            manageOptions: [
                MANAGE_OPTION_PROPERTIES,
                MANAGE_OPTION_PAYMENTS_AND_BILLING,
                MANAGE_OPTION_PROJECT_PLAN,
                MANAGE_OPTION_TIMELINE,
                MANAGE_OPTION_SALES_PIPELINE,
                MANAGE_OPTION_CONTACTS_AND_LEADS,
                MANAGE_OPTION_TASKS,
                MANAGE_OPTION_PROGRESS_TRACKING,
                MANAGE_OPTION_PAYMENTS_AND_BILLING
            ]
        },
        {
            value: "Research",
            label: "Reasearch/BI/Data",
            clusterId: "internal_projects",
            manageOptions: [
                MANAGE_OPTION_PROJECT_PLAN,
                MANAGE_OPTION_TIMELINE,
                MANAGE_OPTION_TASK_ASSIGNMENT,
                MANAGE_OPTION_RESOURCES,
                MANAGE_OPTION_TASKS,
                MANAGE_OPTION_APPROVAL_PROCESS,
                MANAGE_OPTION_FILES,
                MANAGE_OPTION_TIME_TRACKING,
                MANAGE_OPTION_RESEARCH
            ]
        },
        {
            value: "Sales",
            label: "Sales",
            clusterId: "crm",
            manageOptions: [
                MANAGE_OPTION_SALES_PIPELINE,
                MANAGE_OPTION_CONTACTS_AND_LEADS,
                MANAGE_OPTION_PROJECT_PLAN,
                MANAGE_OPTION_TASK_ASSIGNMENT,
                MANAGE_OPTION_PROGRESS_TRACKING,
                MANAGE_OPTION_PAYMENTS_AND_BILLING,
                MANAGE_OPTION_RESOURCES,
                MANAGE_OPTION_TIMELINE
            ]
        },
        {
            value: "Software Development",
            label: "Software Development",
            clusterId: "tech",
            manageOptions: [
                MANAGE_OPTION_SPRINTS,
                MANAGE_OPTION_ROADMAP,
                MANAGE_OPTION_TASKS,
                MANAGE_OPTION_BUGS,
                MANAGE_OPTION_TIME_TRACKING,
                MANAGE_OPTION_PROJECT_GANTT,
                MANAGE_OPTION_BACKLOG,
                MANAGE_OPTION_TIMELINE
            ]
        },
        {
            value: "Other",
            label: "Other",
            clusterId: "client_projects",
            manageOptions: [
                MANAGE_OPTION_PROJECT_PLAN,
                MANAGE_OPTION_TASK_ASSIGNMENT,
                MANAGE_OPTION_TIMELINE,
                MANAGE_OPTION_RESOURCES,
                MANAGE_OPTION_TIME_TRACKING,
                MANAGE_OPTION_APPROVAL_PROCESS,
                MANAGE_OPTION_CLIENT_AND_BILLING,
                MANAGE_OPTION_PROGRESS_TRACKING
            ]
        }
    ]
};
export const getIndustryOptionByValue = (industry)=>{
    return INDUSTRIES_CONFIG.options.filter((option)=>option.value === industry)[0];
};
