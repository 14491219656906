import styled from "@emotion/styled";
import colors from "/styles/colors";
export const StyledButtonLinkComponent = styled.div`
  display: flex;

  a {
    position: relative;
    padding: 16px 32px;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 40px;
    color: ${colors["white"]};
    border: none;
    font-weight: 400;

    text-align: center;
    text-decoration: none;

    &:not(:hover) {
      transition: 0.2s ease all;
    }

    &:hover {
      filter: brightness(90%);
    }

    &.loading {
      cursor: default;
    }

    .spinner {
      position: absolute;
      left: calc(50% - 12px);
      top: calc(50% - 12px);
    }
  }
`;
