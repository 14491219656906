import styled from "@emotion/styled";
import { FOLD_TEST_MAX_WIDTH, MAX_DESKTOP_CONTENT_WIDTH, MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";
import { TOP_LAYER } from "constants/z-index";
import colors from "/styles/colors";
const desktopSlickSize = MAX_DESKTOP_CONTENT_WIDTH - 200;
export const StyledBoardsCarouselComponent = styled.div`
  .boards-carousel-frame {
    .stories-slick-carousel {
      .slick-slider {
        width: 100%;
      }

      .slick-list {
        overflow: visible;
      }

      .slick-arrow {
        z-index: ${TOP_LAYER};
        top: 100px;
        border: 1.5px solid black;

        &.slick-prev {
          left: 25px;
        }

        &.slick-next {
          right: 25px;
        }

        &:hover {
          border-color: ${colors["brand-dark-blue"]};

          &.slick-prev {
            &::before {
              border-color: ${colors["brand-dark-blue"]};
            }
          }

          &.slick-next {
            &::before {
              border-color: ${colors["brand-dark-blue"]};
            }
          }
        }
      }
    }

    .slick-slide {
      cursor: grab;
      width: ${desktopSlickSize}px;
      transition: opacity 0.8s ease;
      opacity: 0.3;
      cursor: grab;

      .quote-component {
        transition: opacity 0.8s ease;
        opacity: 0;
      }

      .board-carousel-image {
        border: none;
      }

      &.slick-center {
        opacity: 1;

        .quote-component {
          opacity: 1;
        }

        .board-carousel-image {
          border: 2px solid;
        }
      }

      &:active {
        cursor: grabbing;
      }
    }

    .custom-arrows {
      position: absolute;
      display: flex;
      justify-content: flex-end;
      top: 82%;
      right: 70px;
      width: 100%;
      max-width: ${desktopSlickSize}px;
    }

    .custom-arrow {
      font-size: 1.688rem;
      top: 50%;
      cursor: pointer;
      z-index: 1;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      border: 1px solid;
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;

      &:hover {
        border-color: ${colors["brand-dark-blue"]};

        svg {
          fill: ${colors["brand-dark-blue"]};
        }
      }

      &.left-arrow {
        margin-right: 16px;
      }
    }

    .board-carousel-item {
      .board-carousel-image-wrapper {
        padding: 24px;
        margin-bottom: 8px;

        .board-carousel-image {
          width: 100%;
          margin: 0px auto;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          transition: all 0.2s ease;
          box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }

    .stop-area {
      position: absolute;
      bottom: 0px;
      height: 300px;
      left: 0px;
      right: 0px;
      pointer-events: none;
    }

    &.remove-border {
      .slick-slide {
        &.slick-center {
          .board-carousel-image {
            box-shadow: none;
            border: none;
          }
        }
      }

      .board-carousel-item .board-carousel-image-wrapper .board-carousel-image {
        box-shadow: none;
      }
    }

    @media (min-width: ${FOLD_TEST_MAX_WIDTH}) {
      &.above-the-fold {
        .slick-slide {
          opacity: 0;
          cursor: default;

          &.slick-center {
            opacity: 1;
            cursor: grab;
          }
        }
      }
    }
  }

  @media (max-width: ${MAX_DESKTOP_CONTENT_WIDTH}px) {
    .boards-carousel-frame {
      .slick-slide {
        width: 800px;
      }

      .custom-arrows {
        right: 60px;
      }
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    padding-bottom: 8px;

    .boards-carousel-frame {
      position: relative;

      .items-without-carousel {
        width: 100vw;
        overflow-x: scroll;
        overflow-y: hidden;
      }

      .custom-arrow {
        display: none;
      }

      .board-carousel-item {
        min-width: 85vw;
        margin-right: -24px;

        .board-carousel-image-wrapper {
          margin-bottom: 16px;
        }
      }
    }
  }
`;
