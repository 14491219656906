import styled from "@emotion/styled";
import { slickStyle } from "./slick.scss";
import { slickThemeStyle } from "./slick-theme.scss";
export const StyledStoriesSlickCarouselComponent = styled.div`
  .items-without-carousel {
    display: flex;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .slick-slider {
    .slick-list {
      .slick-track {
        .slick-slide {
          &.slick-current {
            z-index: 1; // so mouse events will be on current slide. Needed for Fade effect
          }
        }
      }
    }

    .slick-arrow {
      &::before {
        display: inline-block;
        content: "";
        border-width: 2px 2px 0 0;
        border-style: solid;
        border-color: black;
        width: 8px;
        height: 8px;
        transform: rotate(45deg);
      }

      &.slick-prev {
        &::before {
          border-width: 0 0 2px 2px;
        }
      }
    }

    .slick-dots {
      li {
        margin: 0;

        button {
          display: inline-block;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          border: 1px solid #c3c3c3;
          opacity: 1;

          &::before {
            display: none;
          }
        }

        &.slick-active {
          button {
            background-color: black;
          }
        }

        &:not(.slick-active):hover {
          button {
            border-width: 2px;
          }
        }
      }
    }
  }
	
	${slickStyle}
	${slickThemeStyle}
`;
