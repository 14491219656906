import styled from "@emotion/styled";
import { MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";
export const StyledButtonWithAnimationComponent = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  .inline-flex-wrapper {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }

  .lottie-animation-wrapper {
    align-self: flex-end;
    width: 150px;
    position: absolute;
    transform: translateX(95%) translateY(30px);
    top: 0;
    right: 0;
  }

  .button-wrapper {
    align-self: flex-end;
  }

  .button-wrapper.bounce-button {
    animation: bounce linear 0.8s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    .lottie-animation-wrapper {
      left: 0;
      right: 0;
      margin: auto;
      transform: translateX(25%) translateY(86%) rotateZ(45deg);
    }
  }

  @keyframes bounce {
    0% {
      transform: translate(0px, 0px);
    }
    15% {
      transform: translate(-10px, -10px);
    }
    30% {
      transform: translate(0px, 0px);
    }
    45% {
      transform: translate(-7px, -7px);
    }
    60% {
      transform: translate(0px, 0px);
    }
    75% {
      transform: translate(-3px, -3px);
    }
    100% {
      transform: translate(0px, 0px);
    }
  }
`;
