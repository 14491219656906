function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import RegularAssetComponent from "segments/desktop/asset/layouts/regular-asset/regular-asset-component";
import BasicSegments from "./basic-segments";
import TwoColumnsWithCarousel from "./two-columns-with-carousel/two-columns-with-carousel-component";
import PersonComponent from "./person-component/person-component";
import TitleAndSubtitleComponent from "./title-and-subtitle/title-and-subtitle-component";
import VideosGalleryComponent from "./videos-gallery/videos-gallery-component";
import VideosCarouselComponent from "./videos-carousel/videos-carousel-segment-component";
import BoardViewsComponent from "./board-views/board-views-component";
import FormWithTextAndAssetsComponent from "./form-with-text-and-assets/form-with-text-and-assets-component";
import SwitchingTitleWithQuotesCarouselComponent from "./switching-title-with-quotes-carousel/switching-title-with-quotes-carousel-component";
import LottieAnimationComponent from "./lottie-animtaion/lottie-animation-component";
import HeaderWithImageAndTitle from "./header-with-image-and-title/header-with-image-and-title-component";
import FactCardsComponent from "./fact-cards/fact-cards-component";
import SimpoComponent from "./simpo/simpo-component";
import ClusterSelectionComponent from "./cluster-selection/cluster-selection-component";
import ClusterSelectionOneStepComponent from "./cluster-selection-one-step/cluster-selection-one-step-component";
import IndustrySelectionComponent from "./industry-selection/industry-selection-component";
import MultipleTabsComponent from "./multiple-tabs/multiple-tabs-component";
import IntegrationsComponent from "./integrations/integrations-component";
import MainYoutubeVideoComponent from "./main-youtube-video/main-youtube-video-component";
import ButtonWithAnimationComponent from "./button-with-animation/button-with-animation-component";
import SignupWithAnimationComponent from "./signup-with-animation/signup-with-animation-component";
import EmbeddedFormComponent from "./embedded-form/embedded-form-component";
import HeroImageComponent from "./hero-image/hero-image-component";
import SocialProofComponent from "./social-proof/social-proof-component";
export default _object_spread({
    TwoColumnsWithCarousel,
    PersonComponent,
    HeaderWithImageAndTitle,
    TitleAndSubtitleComponent,
    VideosGalleryComponent,
    VideosCarouselComponent,
    FormWithTextAndAssetsComponent,
    SwitchingTitleWithQuotesCarouselComponent,
    LottieAnimationComponent,
    BoardViewsComponent,
    FactCardsComponent,
    MultipleTabsComponent,
    IntegrationsComponent,
    MainYoutubeVideoComponent,
    ButtonWithAnimationComponent,
    SignupWithAnimationComponent,
    EmbeddedFormComponent,
    SimpoComponent,
    ClusterSelectionComponent,
    ClusterSelectionOneStepComponent,
    IndustrySelectionComponent,
    HeroImageComponent,
    SocialProofComponent,
    RegularAssetComponent
}, BasicSegments);
