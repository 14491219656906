import styled from "@emotion/styled";
import { MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";
import colors from "styles/colors";
export const StyledFactQuoteComponent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  color: ${colors["black"]};
  align-items: center;

  .quote-person-image {
    img {
      height: 60px;
      width: 120px;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    margin-left: 36px;
    line-height: 27px;
    flex: 1 1 100%;

    .quote {
      word-break: break-word;
    }

    .details {
      font-size: 0.875rem;
      margin-top: 16px;

      .person-name,
      .company-name {
        font-weight: 700;
      }

      .seperator {
        margin: 0px 4px;
      }
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    position: relative;
    flex-direction: column;
    align-items: center;
    padding: 16px 32px 32px;

    .content-container {
      margin-left: 0px;
      text-align: center;
    }
  }
`;
