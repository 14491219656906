import { TABLET_QUERY_MAX_WIDTH } from "/constants/vp-sizes";
import colors from "/styles/colors";
export const componentStyles = new String(`.industry-selection-first-step-wrapper{display:-webkit-box;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-moz-box-pack:center;-ms-flex-pack:center;justify-content:center;.industry-selection-questions{display:-webkit-box;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;-moz-box-orient:vertical;-moz-box-direction:normal;-ms-flex-direction:column;flex-direction:column;min-height:80vh;.industry-question-wrapper{display:-webkit-box;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-webkit-align-items:center;-moz-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;-moz-box-orient:vertical;-moz-box-direction:normal;-ms-flex-direction:column;flex-direction:column;padding-top:15vh;-webkit-transition:padding.2s;-moz-transition:padding.2s;-o-transition:padding.2s;transition:padding.2s;.industry-question{font-size:1.375rem;padding-top:8px;text-align:center;strong {
            font-weight: bold;
          }
        }.industry-selection-options-wrapper{padding-top:24px;.Select-control{cursor:pointer}.Select-menu-outer{max-height:none;.Select-menu{min-height:255px}}}&.industry-selected{padding-top:5vh}}.manage-question-wrapper{padding-top:60px;display:-webkit-box;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-webkit-align-items:center;-moz-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;-moz-box-orient:vertical;-moz-box-direction:normal;-ms-flex-direction:column;flex-direction:column;opacity:0;-webkit-transition:opacity.2s ease-in-out;-moz-transition:opacity.2s ease-in-out;-o-transition:opacity.2s ease-in-out;transition:opacity.2s ease-in-out;.select-disclaimer{font-size:.875rem;font-weight:300;text-align:center;padding-top:16px}.manage-options-question{font-size:1.375rem;padding-top:16px;text-align:center;strong {
            font-weight: bold;
          }
        }&.visible{opacity:1}}}.next-wrapper{-webkit-box-align:center;-webkit-align-items:center;-moz-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;font-size:1rem;letter-spacing:-.01em;line-height:30px;color:${colors["brand-blue"]};opacity:0;-webkit-transition:opacity.2s;-moz-transition:opacity.2s;-o-transition:opacity.2s;transition:opacity.2s;svg {
        fill: ${colors["brand-blue"]};
      }

      &.visible {
        opacity: 1;
      }

      .next-link {
        display: flex;
        flex-direction: column;
        align-items: center;

        .next-circle {
          background: rgba(43, 185, 255, 1);
          box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
          width: 28px;
          height: 28px;
          border-radius: 50%;
          color: white;
          font-size: 1.125rem;
          line-height: 24px;
          text-align: center;

          .arrow {
            padding-top: 2px;
            padding-left: 2px;
          }

          svg {
            fill: white;
          }
        }
      }
    }}@media(max-width:${TABLET_QUERY_MAX_WIDTH}){.industry-selection-first-step-wrapper{}}`);
componentStyles.__hash = "b8ef98c1e9da05c6";
export const stepNumberStyles = new String(".step-number-circle.jsx-f17ebfa745dafa4{border:1px solid rgba(43,185,255,1);width:28px;height:28px;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;color:rgba(43,185,255,1);font-size:1.125rem;line-height:24px;text-align:center}");
stepNumberStyles.__hash = "f17ebfa745dafa4";
