import { CLUSTER_CLICKED, CLUSTER_SELECTED } from "/constants/bigbrain-event-types";
import { USER_SELECTED_CLUSTER_COOKIE } from "/constants/cookies";
export const CLUSTER_WITH_SOLUTION_OPTION_VALUE = "cluster-with-solution ";
export const CLUSTER_WITH_SOLUTION_CONFIG = {
    firstStepHtmlTitle: "What would you like <br><strong>to manage with monday.com?</strong>",
    otherDetailsHtmlTitle: "<strong>Manage your team projects visually</strong><br>",
    subtitleHtml: "You can always change this later",
    secondStepHtmlTitle: "<strong>Manage your team projects visually</strong><br>",
    secondStepSubtitleHtml: "Choose from multiple templates or create your own workflow",
    itemClickedEvent: CLUSTER_CLICKED,
    itemSelectedEvent: CLUSTER_SELECTED,
    selectedItemCookieName: USER_SELECTED_CLUSTER_COOKIE,
    secondStepScreen: "Solutions",
    options: [
        {
            id: "internal_projects",
            text: "Projects",
            image: "/static/img/cluster-selection/projects.png",
            solutionsImage: "/static/img/cluster-selection/solutions/internal_projects_solutions.png",
            secondStepHtmlTitle: "<strong>Manage projects visually</strong><br>"
        },
        {
            id: "client_projects",
            text: "Projects for Clients",
            image: "/static/img/cluster-selection/client_projects.png",
            solutionsImage: "/static/img/cluster-selection/solutions/client_projects_solutions.png",
            secondStepHtmlTitle: "<strong>Manage clients projects' visually</strong><br>"
        },
        {
            id: "todos",
            text: "Tasks & To Do's",
            image: "/static/img/cluster-selection/todos.png",
            solutionsImage: "/static/img/cluster-selection/solutions/todos_solutions.png",
            secondStepHtmlTitle: "<strong>Manage tasks & to do's visually</strong><br>"
        },
        {
            id: "crm",
            text: "Sales & CRM",
            image: "/static/img/cluster-selection/crm.png",
            solutionsImage: "/static/img/cluster-selection/solutions/crm_solutions.png",
            secondStepHtmlTitle: "<strong>Manage sales & CRM visually</strong><br>"
        },
        {
            id: "orders",
            text: "Orders & Production",
            image: "/static/img/cluster-selection/orders.png",
            solutionsImage: "/static/img/cluster-selection/solutions/orders_solutions.png",
            secondStepHtmlTitle: "<strong>Manage orders & production visually</strong><br>"
        },
        {
            id: "marketing",
            text: "Marketing & Creative",
            image: "/static/img/cluster-selection/marketing.png",
            solutionsImage: "/static/img/cluster-selection/solutions/marketing_solutions.png",
            secondStepHtmlTitle: "<strong>Manage marketing visually</strong><br>"
        },
        {
            id: "tech",
            text: "Product and R&D",
            image: "/static/img/cluster-selection/agile.png",
            solutionsImage: "/static/img/cluster-selection/solutions/agile_and_product_roadmap_solutions.png",
            secondStepHtmlTitle: "<strong>Manage product and R&D visually</strong><br>"
        },
        {
            id: "other",
            text: "Other",
            image: "/static/img/cluster-selection/other.png",
            detailsImage: "/static/img/cluster-selection/other_details.png",
            detailsText: "Manage anything with monday.com",
            isOther: true,
            solutionsImage: "/static/img/cluster-selection/solutions/other_solutions.png",
            secondStepHtmlTitle: "<strong>Manage your team's projects visually</strong><br>"
        }
    ]
};
