import { CLUSTER_CLICKED, CLUSTER_SELECTED } from "/constants/bigbrain-event-types";
import { USER_SELECTED_CLUSTER_COOKIE } from "/constants/cookies";
export const CLUSTER_OPTION_VALUE = "cluster";
export const CLUSTER_CONFIG = {
    firstStepHtmlTitle: "What would you like <br><strong>to manage with monday.com?</strong>",
    secondStepHtmlTitle: "That's great! <br><strong>Now, let's get to work</strong>",
    otherDetailsHtmlTitle: "With more than 200 templates <br><strong>we got you covered</strong>",
    subtitleHtml: "You can always change this later",
    itemClickedEvent: CLUSTER_CLICKED,
    itemSelectedEvent: CLUSTER_SELECTED,
    selectedItemCookieName: USER_SELECTED_CLUSTER_COOKIE,
    options: [
        {
            id: "internal_projects",
            text: "Projects",
            image: "/static/img/cluster-selection/projects.png"
        },
        {
            id: "client_projects",
            text: "Projects for Clients",
            image: "/static/img/cluster-selection/client_projects.png"
        },
        {
            id: "todos",
            text: "Tasks & To Do's",
            image: "/static/img/cluster-selection/todos.png"
        },
        {
            id: "crm",
            text: "Sales & CRM",
            image: "/static/img/cluster-selection/crm.png"
        },
        {
            id: "orders",
            text: "Orders & Production",
            image: "/static/img/cluster-selection/orders.png"
        },
        {
            id: "marketing",
            text: "Marketing & Creative",
            image: "/static/img/cluster-selection/marketing.png"
        },
        {
            id: "tech",
            text: "Product and R&D",
            image: "/static/img/cluster-selection/agile.png"
        },
        {
            id: "other",
            text: "Other",
            image: "/static/img/cluster-selection/other.png",
            detailsImage: "/static/img/cluster-selection/other_details.png",
            detailsText: "Manage anything with monday.com",
            isOther: true
        }
    ]
};
