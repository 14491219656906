import styled from "@emotion/styled";
export const StyledHeroImageComponent = styled.div`
  position: relative;
  overflow: hidden;

  .hero-image {
    img {
      width: 100%;
    }
  }

  .text-and-assets-background {
    position: absolute;
    z-index: 2;
    height: 180vh;
    width: 180vh;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    border-radius: 50%;
  }

  .text-and-assets-component-wrapper {
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    margin-left: 96px;

    .paragaph-and-asset-component {
      flex-grow: 1;
    }
  }
`;
