import styled from "@emotion/styled";
import colors from "/styles/colors";
export const StyledYoutubeVideoWithPosterComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;

  .poster-image-wrapper {
    top: 0;
    left: 0;
    transition: opacity 0.1s ease-out;
    opacity: 1;

    &.with-shadow {
      box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .video-component-wrapper {
    opacity: 0;

    iframe {
      display: block;
    }
  }

  &:hover {
    .play-button-container {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }

  .play-button-container {
    border: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: ${colors["white"]};
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
    pointer-events: none;
    transition: transform 0.2s ease-in-out;

    .play-button {
      box-sizing: border-box;
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-right-color: transparent;
      border-left-color: rgb(51, 51, 51);
      border-style: solid;
      border-width: 16px 0 16px 28px;
      position: absolute;
      left: calc(50% + 4px);
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &.lg {
      width: 128px;
      height: 128px;

      .play-button {
        border-width: 24px 0 24px 36px;
        left: calc(50% + 8px);
      }
    }
  }

  &.hide-poster {
    .poster-image-wrapper {
      opacity: 0;
      z-index: -1;
    }

    .video-component-wrapper {
      opacity: 1;
      z-index: 1;
    }
  }
`;
