import colors from "/styles/colors";
const _defaultExport = new String(`.video-carousel-item-component{display:-webkit-box;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;-moz-box-orient:vertical;-moz-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-align:center;-webkit-align-items:center;-moz-box-align:center;-ms-flex-align:center;align-items:center;.video-part{position:relative;margin:0 16px;-webkit-box-shadow:0 2px 16px 0 rgba(0,0,0,.2);-moz-box-shadow:0 2px 16px 0 rgba(0,0,0,.2);box-shadow:0 2px 16px 0 rgba(0,0,0,.2);-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;cursor:pointer;&:hover{.play-button-container{background-color:${colors["white-smoke"]}}}.poster-image-wrapper{display:-webkit-box;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }.play-button-container{position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%,-50%);-moz-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);-o-transform:translate(-50%,-50%);transform:translate(-50%,-50%);width:56px;height:56px;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;background-color:white;-webkit-box-shadow:0 2px 16px 0 rgba(0,0,0,.2);-moz-box-shadow:0 2px 16px 0 rgba(0,0,0,.2);box-shadow:0 2px 16px 0 rgba(0,0,0,.2);pointer-events:none;.play-button{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;border-color:transparent transparent transparent rgb(51,51,51);border-style:solid;border-width:9px 0 9px 14px;position:absolute;left:-webkit-calc(50% + 2px);left:-moz-calc(50% + 2px);left:calc(50% + 2px);top:50%;-webkit-transform:translate(-50%,-50%);-moz-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);-o-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}}}&.mobile-layout{.video-part{overflow:hidden;.poster-image-wrapper{position:absolute;top:0;-webkit-transition:opacity.1s ease-out;-moz-transition:opacity.1s ease-out;-o-transition:opacity.1s ease-out;transition:opacity.1s ease-out;opacity:1}.video-component-wrapper{iframe {
            display: block;
          }
          opacity: 0;}}}.title-container.video-title{margin-top:24px;.colorful-title{font-size:.956rem;.colored{font-weight:bold}}}}`);
_defaultExport.__hash = "9176bd70defa8bb3";
export default _defaultExport;
